<script>
import Form, { formCreate } from '../../../../../components/form';

export default {
  extends: Form,
  data() {
    return {
      buttons: {
        submit: false,
      },
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  created() {
    this.getFormRule('sfaCenter_performanceMange_performance_progress_form');
  },
};
</script>
